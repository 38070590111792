import { Controller } from '@hotwired/stimulus'
import { pausePlayers } from '../utils/pausePlayers'

/*
 * Description
 * -------
 *
 * Loads Vimeo iframe and creates player.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 *
 * Values
 * -------
 *
 * - watchTimesPath  - (required) - API endpoint to save watch time
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="vimeo-player"
 *   data-vimeo-player-watch-times-path-value="http://example.com"
 *   data-vimeo-player-video-url-value="http://example.com"
 * >
 * </div>
 */

export default class extends Controller {
  static values = { watchTimesPath: String }

  async connect() {
    const Vimeo = await import('@vimeo/player')

    const player = new Vimeo.default(this.element, {
      byline: false,
      title: false,
    })

    player.on('play', () => {
      fetch(this.watchTimesPathValue, {
        method: 'POST',
      })

      pausePlayers(this.element)

      this.element.dataset.played = true
    })
  }
}
