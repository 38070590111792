import { apmBase } from '@elastic/apm-rum';
import { signInWithCustomToken as firebaseSignInWithCustomToken } from '@firebase/auth';
import * as Sentry from '@sentry/browser';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie';
import { isPWA } from './utils/dev';
import { registerServiceWorker, unregisterServiceWorker } from './utils/serviceWorker';
const CUSTOM_TOKEN_COOKIE_NAME = 'custom_token';
initializeApp({
    apiKey: window.__fw_app__.firebase.apiKey,
    authDomain: window.__fw_app__.firebase.authDomain,
    projectId: window.__fw_app__.firebase.projectId,
    messagingSenderId: window.__fw_app__.firebase.messagingSenderId,
    appId: window.__fw_app__.firebase.appId,
});
getAuth().tenantId = window.__fw_app__.firebase.tenantId;
getAuth().onAuthStateChanged((user) => {
    if (user) {
        registerServiceWorker();
    }
    else {
        if (!isPWA())
            unregisterServiceWorker();
    }
});
export const signInWithCustomToken = () => {
    const customToken = Cookies.get(CUSTOM_TOKEN_COOKIE_NAME);
    if (!customToken)
        return;
    firebaseSignInWithCustomToken(getAuth(), customToken)
        .then(() => Cookies.remove(CUSTOM_TOKEN_COOKIE_NAME))
        .catch((error) => {
        apmBase.captureError(error);
        Sentry.captureException(error);
    });
};
