import { Controller } from '@hotwired/stimulus';
/*
 * Description
 * -------
 *
 * Handles popover.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * reference   - (required) - reference element that toggles popover
 * popover     - (required) - element containing popover
 *
 * Values
 * -------
 *
 * placement - (optional) - https://popper.js.org/docs/v2/constructors/#placement
 *
 * Usage
 * -------
 *
 * <div data-controller="popover">
 *   <div data-popover-target="popover">
 *     Popover content
 *   </div>
 *   <div data-popover-target="reference" data-action="mouseover->popover#show mouseout->popover#hide">
 *     Show popover
 *   </div>
 * </div>
 */
export default class default_1 extends Controller {
    static targets = ['reference', 'popover'];
    static values = {
        placement: { type: String, default: 'top' },
    };
    async connect() {
        const { createPopper } = await import('@popperjs/core');
        this.popperInstance = createPopper(this.referenceTarget, this.popoverTarget, {
            placement: this.placementValue,
            modifiers: [
                {
                    name: 'computeStyles',
                    options: {
                        gpuAcceleration: false,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 0],
                    },
                },
            ],
        });
    }
    disconnect() {
        this.popperInstance?.destroy();
    }
    show() {
        this.popoverTarget.classList.remove('hidden');
        this.popperInstance?.update();
    }
    hide() {
        this.popoverTarget.classList.add('hidden');
        this.popperInstance?.update();
    }
}
