import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { Buffer } from 'buffer'
import { generateFileChecksum } from '../utils/checksum'
import { toast } from '../utils/toast'

/*
 * Description
 * -------
 *
 * Handles message form.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 */

export default class extends Controller {
  static targets = [
    'chat',
    'imageElementContainer',
    'imageElement',
    'imageResourceInput',
    'imageRemoveButton',
    'imageButton',
    'imageLoader',
    'messageInput',
    'messageSendButton',
    'tipModal',
    'tipInfo',
    'tipRequirementInfo',
    'visibleAmount',
    'tipInput',
    'sendModal',
    'form',
    'loadRecent',
    'newMessagesAlert',
  ]

  static values = { minTip: Number }

  connect() {
    this.scrollChatToBottom()
  }

  scrollChatToBottom() {
    this.chatTarget.scrollTo(0, this.chatTarget.scrollHeight)
  }

  isChatScrolledToBottom() {
    return (
      Math.abs(
        this.chatTarget.scrollHeight - this.chatTarget.scrollTop - this.chatTarget.clientHeight,
      ) < 50
    )
  }

  handleChatScroll() {
    if (this.isChatScrolledToBottom() && this.hasNewMessagesAlertTarget) {
      this.removeNewMessagesAlert()
    }
  }

  validateImage(image) {
    if (image.size > 10485760) {
      toast('This file exceeds the maximum allowed file size (10 MB)')

      return false
    }

    return true
  }

  showImageContainer() {
    this.imageElementContainerTarget.classList.remove('hidden')
  }

  hideImageContainer() {
    this.imageElementContainerTarget.classList.add('hidden')
  }

  showImageLoader() {
    this.imageLoaderTarget.classList.remove('hidden')
  }

  hideImageLoader() {
    this.imageLoaderTarget.classList.add('hidden')
  }

  showImageRemoveButton() {
    this.imageRemoveButtonTarget.classList.remove('hidden')
  }

  hideImageRemoveButton() {
    this.imageRemoveButtonTarget.classList.add('hidden')
  }

  showMessageSendButtons() {
    this.messageSendButtonTargets.forEach((messageSendButtonTarget) =>
      messageSendButtonTarget.classList.remove('hidden'),
    )
  }

  hideMessageSendButtons() {
    this.messageSendButtonTargets.forEach((messageSendButtonTarget) =>
      messageSendButtonTarget.classList.add('hidden'),
    )
  }
  enableAddImageButton() {
    this.imageButtonTarget.classList.remove('disabled')
  }

  disableAddImageButton() {
    this.imageButtonTarget.classList.add('disabled')
  }

  enableMessageSendButtons() {
    this.messageSendButtonTargets.forEach((messageSendButtonTarget) =>
      messageSendButtonTarget.removeAttribute('disabled'),
    )
  }

  disableMessageSendButtons() {
    this.messageSendButtonTargets.forEach((messageSendButtonTarget) =>
      messageSendButtonTarget.setAttribute('disabled', true),
    )
  }

  setImageElementSrc(file) {
    this.imageElementTarget.src = URL.createObjectURL(file)
  }

  removeImageElementSrc() {
    this.imageElementTarget.src = ''
  }

  setImageResourceInputValue(value) {
    this.imageResourceInputTarget.value = value
  }

  removeImageResourceInputValue() {
    this.imageResourceInputTarget.value = null
  }

  setMessageInputRequired() {
    this.messageInputTarget.required = true
  }

  removeMessageInputRequired() {
    this.messageInputTarget.required = false
  }

  resetMessageInputHeight() {
    this.messageInputTarget.style.height = ''
  }

  async handleImageAdd(event) {
    const image = event.target.files[0]

    if (!image || !this.validateImage(image)) {
      return this.removeImage()
    }

    this.showImageLoader()
    this.showImageContainer()
    this.disableAddImageButton()
    this.hideImageRemoveButton()
    this.showMessageSendButtons()
    this.disableMessageSendButtons()
    this.removeMessageInputRequired()
    this.setImageElementSrc(event.target.files[0])

    const checksum = await generateFileChecksum(image)

    const createResourceResponse = await fetch('/supporters/api/v1/resources/images/googles', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        byte_size: image.size,
        checksum: Buffer.from(checksum, 'hex').toString('base64'),
      }),
    })

    if (!createResourceResponse.ok) {
      toast('Something went wrong. Please try again.')
      return this.removeImage()
    }

    const {
      uploadData: { url, headers, signedId },
      resourceId,
    } = await createResourceResponse.json()

    const uploadResourceResponse = await fetch(url, {
      method: 'PUT',
      headers: { ...headers },
      body: image,
    })

    if (!uploadResourceResponse.ok) {
      toast('Something went wrong. Please try again.')
      return this.removeImage()
    }

    const confirmResourceResponse = await fetch(
      `/supporters/api/v1/resources/images/googles/${resourceId}`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          signed_id: signedId,
        }),
      },
    )

    if (!confirmResourceResponse.ok) {
      toast('Something went wrong. Please try again.')
      return this.removeImage()
    }

    this.hideImageLoader()
    this.showImageRemoveButton()
    this.enableMessageSendButtons()
    this.setImageResourceInputValue(resourceId)
  }

  removeImage() {
    this.hideImageLoader()
    this.hideImageContainer()
    this.enableAddImageButton()
    this.removeImageElementSrc()
    this.setMessageInputRequired()
    this.enableMessageSendButtons()
    this.removeImageResourceInputValue()

    if (!this.messageInputTarget.value.trim()) {
      this.hideMessageSendButtons()
    }
  }

  handleMessageInputKeypress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      Rails.stopEverything(e)
      this.submitForm()
    }
  }

  handleMessageWithTipInputKeyPress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      Rails.stopEverything(e)
      this.sendWithTip()
    }
  }

  handleMessageInputInput(e) {
    if (e.target.value.trim()) {
      this.showMessageSendButtons()
    } else {
      if (!this.imageElementTarget.currentSrc) {
        this.hideMessageSendButtons()
      }
    }

    this.resetMessageInputHeight()
    this.messageInputTarget.style.height = this.messageInputTarget.scrollHeight + 2 + 'px'
  }

  openTipModal() {
    this.tipModalTarget.classList.remove('hidden')
  }

  closeTipModal() {
    this.tipModalTarget.classList.add('hidden')
    this.openSendModalAfterTipAdd = false
  }

  handleTipInputKeydown(e) {
    if (['+', '-', 'E', 'e'].includes(e.key)) {
      Rails.stopEverything(e)
    }

    if (e.key == 'Enter') {
      Rails.stopEverything(e)
    }
  }

  handleTipInputBlur(e) {
    const parsedValue = parseFloat(e.target.value).toFixed(2)
    e.target.value = parsedValue
  }

  addTip(e) {
    Rails.stopEverything(e)

    const formData = new FormData(e.target)
    const tip = formData.get('tip')

    this.setVisibleAmount(`$${tip}`)
    this.setTipInputValue(parseInt((tip * 100).toFixed(2)))
    this.showTipInfo()
    this.hideTipRequirementInfo()

    if (this.openSendModalAfterTipAdd) {
      this.openSendModalAfterTipAdd = false
      this.openSendModal()
    }

    this.closeTipModal()
  }

  removeTip() {
    this.hideTipInfo()
    this.setTipInputValue(null)
    this.showTipRequirementInfo()
  }

  hideTipInfo() {
    this.tipInfoTarget.classList.add('hidden')
  }

  showTipInfo() {
    this.tipInfoTarget.classList.remove('hidden')
  }

  hideTipRequirementInfo() {
    if (this.hasTipRequirementInfoTarget) {
      this.tipRequirementInfoTarget?.classList.add('hidden')
    }
  }

  showTipRequirementInfo() {
    if (this.hasTipRequirementInfoTarget) {
      this.tipRequirementInfoTarget?.classList.remove('hidden')
    }
  }

  setVisibleAmount(amount) {
    this.visibleAmountTargets.forEach(
      (visibleAmountTarget) => (visibleAmountTarget.innerHTML = amount),
    )
  }

  setTipInputValue(value) {
    this.tipInputTarget.value = value
  }

  openSendModal() {
    this.sendModalTarget.classList.remove('hidden')
  }

  closeSendModal() {
    this.sendModalTarget.classList.add('hidden')
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  sendWithTip() {
    if (!this.formTarget.reportValidity()) return

    if (!this.minTipValue && !this.tipInputTarget.value) {
      return this.submitForm()
    }

    if (
      this.minTipValue &&
      (!this.tipInputTarget.value || this.tipInputTarget.value < this.minTipValue)
    ) {
      this.openSendModalAfterTipAdd = true
      return this.openTipModal()
    }

    this.openSendModal()
  }

  handleBeforeStreamRender(e) {
    const originalRender = e.detail.render
    const scrollPosition = this.chatTarget.scrollTop

    // Dont show new messages alert if chat is scrolled to bottom
    if (
      e.srcElement.getAttribute('target') === 'new_messages_alert' &&
      this.isChatScrolledToBottom()
    ) {
      return Rails.stopEverything(e)
    }

    // If there is a new message and chat is scrolled down, scroll to bottom to show new message
    if (
      e.srcElement.getAttribute('target') === 'load_recent' &&
      e.srcElement.firstChild.content.childElementCount > 1 &&
      this.isChatScrolledToBottom()
    ) {
      e.detail.render = (element) => {
        originalRender(element)
        this.scrollChatToBottom()
      }

      return
    }

    e.detail.render = (element) => {
      this.chatTarget.scrollTop = scrollPosition || 1
      originalRender(element)
    }
  }

  removeNewMessagesAlert() {
    this.newMessagesAlertTarget.replaceChildren()
    this.newMessagesAlertTarget.classList.add('hidden')
  }
}
