export const registerServiceWorker = () => {
    if (window.__fw_app__.features.pwa && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/supporters' });
    }
};
window.registerServiceWorker = registerServiceWorker;
export const unregisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
    }
};
window.unregisterServiceWorker = unregisterServiceWorker;
