import { signInWithCustomToken } from './firebase';
import { isWLA } from './utils/dev';
import { disableFormElements, enableFormElements } from './utils/form';
import { scrollLock } from './utils/scrollLock';
document.addEventListener('turbo:frame-missing', (event) => {
    if (event.detail.response.redirected) {
        event.preventDefault();
        event.detail.visit(event.detail.response);
    }
});
document.addEventListener('turbo:before-cache', () => {
    window.Stimulus.controllers.forEach((controller) => controller.teardown?.());
    Array.from(document.querySelectorAll('.modal')).forEach((modal) => modal.classList.add('hidden'));
    document.documentElement.removeAttribute('aria-loading');
    scrollLock('remove');
});
document.addEventListener('turbo:before-visit', (event) => {
    if (!isWLA())
        signInWithCustomToken();
    if (window.Android?.handleRedirect(event.detail.url))
        event.preventDefault();
});
document.addEventListener('turbo:submit-start', (e) => {
    if (e.target instanceof HTMLFormElement)
        disableFormElements(e.target);
});
document.addEventListener('turbo:submit-end', (e) => {
    if (e.target instanceof HTMLFormElement)
        enableFormElements(e.target);
});
document.addEventListener('turbo:morph', () => {
    window.Stimulus.controllers.forEach((controller) => controller.documentMorph?.());
});
