import { Controller } from '@hotwired/stimulus';
/*
 * Description
 * -------
 *
 * Creates QR code.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * content - (required) - QR code content
 * size    - (optional) - size of QR code
 *
 * Usage
 * -------
 *
 * <div data-controller="qr" data-qr-content-value="content"></div>
 */
export default class default_1 extends Controller {
    static values = {
        content: { type: String },
        size: { type: Number, default: 400 },
    };
    async connect() {
        const { default: QRCodeStyling } = await import('qr-code-styling');
        const qrCode = new QRCodeStyling({
            width: this.sizeValue,
            height: this.sizeValue,
            type: 'svg',
            data: this.contentValue,
            cornersSquareOptions: { type: 'square' },
            dotsOptions: { type: 'square' },
        });
        qrCode.append(this.element);
        this.element.firstElementChild?.setAttribute('width', '100%');
        this.element.firstElementChild?.setAttribute('height', 'auto');
        this.element.firstElementChild?.setAttribute('style', `max-width:${this.sizeValue}px;`);
    }
}
