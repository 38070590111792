import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles badges edit.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * visible - (required) - visible badges container
 * hidden  - (required) - hidden badges container
 *
 * Usage
 * -------
 *
 * <div data-controller="badges" data-badges-handle-class='.class'>
 *   <ul data-badges-target="visible">
 *     <li>badge 1</li>
 *   </ul>
 *   <ul data-badges-target="hidden">
 *     <li>badge 2</li>
 *   </ul>
 * </div>
 */

const GROUP = 'badges'

export default class extends Controller {
  static targets = ['visible', 'hidden']

  static classes = ['handle']

  async connect() {
    const { default: Sortable } = await import('sortablejs')

    new Sortable(this.visibleTarget, {
      group: GROUP,
      handle: this.handleClass,
      animation: 150,
      onEnd: (event) => {
        event.item.firstElementChild.value = event.to.id === 'hidden'
      },
    })

    new Sortable(this.hiddenTarget, {
      group: GROUP,
      handle: this.handleClass,
      animation: 150,
      onEnd: (event) => {
        event.item.firstElementChild.value = event.to.id === 'hidden'
      },
    })
  }
}
