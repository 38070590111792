import { Controller } from '@hotwired/stimulus'
import { isDesktop } from '../utils/dev'
/*
 * Description
 * -------
 *
 * Share a URL using the Web Share API or opens dropdown if API is not available.
 * Can be used on any element.
 * Can be used multiple times in the document.
 * Must be used together with the dropdown controller.
 *
 * Values
 * -------
 *
 * url - (required) - URL to share
 *
 * Usage
 * -------
 *
 * <div data-controller="post-share" data-action="post-share:dropdown->dropdown#toggle" data-post-share-url-value="http://example/com">
 *   <button data-action="click->post-share#share">
 *     Share
 *   </button>
 * </div>
 */

export default class extends Controller {
  static values = { url: String }

  async share() {
    if (isDesktop()) {
      return this.dispatch('dropdown')
    }

    try {
      await navigator.share({ url: this.urlValue })
    } catch (error) {
      // User aborted share
      if (error.toString().includes('AbortError')) return

      this.dispatch('dropdown')
    }
  }
}
