import Toastify from 'toastify-js';
export const toast = (text, type = 'error') => {
    const toastify = Toastify({
        text: `
      <div class="alert__background"></div>
      <div class="alert__content" role="alert">
        <p class="alert__text">
          ${text}
        </p>
        <button class="alert__close toast-close" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none" role="img">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.70714 8.00004L15.3848 1.32234L14.6777 0.615234L8.00004 7.29293L1.32234 0.615234L0.615234 1.32234L7.29293 8.00004L0.615234 14.6777L1.32234 15.3848L8.00004 8.70714L14.6777 15.3848L15.3848 14.6777L8.70714 8.00004Z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    `,
        duration: 2500,
        close: false,
        gravity: 'top',
        position: 'center',
        onClick() {
            toastify.hideToast();
        },
        escapeMarkup: false,
        stopOnFocus: true,
        className: `alert alert--${type}`,
    });
    toastify.showToast();
};
