import { Controller } from '@hotwired/stimulus'
import { pausePlayers } from '../utils/pausePlayers'

/*
 * Description
 * -------
 *
 * Handles YouTube player.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * element - (required) - element to initialize player in
 *
 * Values
 * -------
 *
 * - videoId        - (required) - YouTube video ID
 * - watchTimesPath - (required) - API endpoint to save watch time
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="youtube-player"
 *   data-youtube-player-watch-times-path-value="http://example.com"
 *   data-youtube-player-video-id-value="dQw4w9WgXcQ"
 * >
 *  <button type="button" data-action="click->youtube-player#createPlayer">
 *    Play
 *  </button>
 * </div>
 */

export default class extends Controller {
  static targets = ['element']

  static values = { videoId: String, watchTimesPath: String }

  createPlayer() {
    this.element.dataset.played = true

    if (!window.YT?.loaded) {
      this.createPlayerTimeout = setTimeout(() => {
        this.createPlayer()
      }, 500)

      return
    }

    new window.YT.Player(this.elementTarget, {
      videoId: this.videoIdValue,
      playerVars: {
        autoplay: 1,
        playsinline: 1,
        modestbranding: 1,
      },
      events: {
        onStateChange: (event) => {
          if (event.data == YT.PlayerState.PLAYING) {
            fetch(this.watchTimesPathValue, {
              method: 'POST',
            })

            pausePlayers(this.elementTarget)
          }
        },
      },
    })
  }

  disconnect() {
    if (!this.createPlayerTimeout) return

    clearTimeout(this.createPlayerTimeout)
  }
}
