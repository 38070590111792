import { apmBase, init as initAmp } from '@elastic/apm-rum';
initAmp({
    serviceName: 'membership',
    environment: window.__fw_app__.elasticRum.environment,
    active: window.__fw_app__.elasticRum.enabled,
    transactionSampleRate: window.__fw_app__.elasticRum.transactionSampleRate,
    serverUrl: window.__fw_app__.elasticRum.serverUrl,
    disableInstrumentations: window.__fw_app__.elasticRum.disableInstrumentations,
    distributedTracingOrigins: ['https://stream.mux.com', 'https://image.mux.com'],
});
apmBase.setUserContext({
    id: window.__fw_app__.elasticRum.userId,
    email: window.__fw_app__.elasticRum.userEmail,
});
