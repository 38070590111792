import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

/*
 * Description
 * -------
 *
 * Handles dropdown.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * button   - (required) - element that toggles dropdown
 * items    - (required) - element containing dropdown items
 * submenu  - (optional) - elements containing default items and sumbenu items
 *
 * Values
 * -------
 *
 * placement - (optional) - https://popper.js.org/docs/v2/constructors/#placement
 * sameWidth - (optional) - whether dropdown items should have the same width as button
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="dropdown"
 *   data-dropdown-placement-value="bottom-end"
 *   data-dropdown-same-width-value="true"
 * >
 *   <button data-dropdown-target="button" data-action="click->dropdown#toggle">
 *     Open
 *   </button>
 *   <div data-dropdown-target="items">
 *     <div>Dropdown item</div>
 *     <div>Dropdown item</div>
 *   </div>
 * </div>
 */

export default class extends Controller {
  static targets = ['button', 'items', 'submenu']

  static values = {
    placement: { type: String, default: 'bottom-start' },
    sameWidth: Boolean,
  }

  sameWidthModifier = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
    },
  }

  isOpen = false

  async connect() {
    const { createPopper } = await import('@popperjs/core')

    useClickOutside(this)

    this.popperInstance = createPopper(this.buttonTarget, this.itemsTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        ...(this.sameWidthValue ? [this.sameWidthModifier] : []),
      ],
    })
  }

  disconnect() {
    this.popperInstance?.destroy()
  }

  clickOutside(e) {
    if (this.isOpen) {
      e.preventDefault()
      this.hide()
    }
  }

  hide() {
    this.itemsTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('active')
    this.popperInstance?.update()
    this.isOpen = false
  }

  show() {
    this.itemsTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('active')
    this.popperInstance?.update()
    this.isOpen = true
  }

  toggle() {
    if (this.isOpen) {
      this.hide()
    } else {
      this.show()
    }
  }

  toggleSubmenu() {
    this.submenuTargets[0].classList.toggle('hidden')
    this.submenuTargets[1].classList.toggle('hidden')
  }

  documentMorph() {
    this.popperInstance?.update()
  }
}
