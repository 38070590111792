import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

/*
 * Description
 * -------
 *
 * Monitors CSS file load, retrying if it fails and sending error to Sentry.
 * Must be used on link element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <link href="/styles/css-variables.scss.css" data-controller="css-load">
 */

const LIMIT = 3

export default class extends Controller {
  connect() {
    this.attempts = 0
    this.href = this.element.href

    setTimeout(() => {
      this.check()
    }, 1000)
  }

  check() {
    if (this.attempts >= LIMIT) {
      return Sentry.captureMessage('CSS load error', {
        extra: { href: this.href },
      })
    }

    const styleSheet = Array.from(document.styleSheets).find((styleSheet) => {
      return styleSheet.href && styleSheet.href.includes(this.href)
    })

    if (!styleSheet) return

    try {
      if (styleSheet.cssRules.length === 0) {
        this.attempts += 1

        this.element.href = `${this.href}?${Date.now()}`

        setTimeout(() => {
          this.check()
        }, 1500)
      }
    } catch (error) {}
  }
}
