import * as Sentry from '@sentry/browser';
import { isDevelopment } from './utils/dev';
if (!isDevelopment()) {
    Sentry.init({
        dsn: 'https://6eb4409b1b2d4fc9b4bfd472f4264806@o276638.ingest.sentry.io/6076975',
        ignoreErrors: [
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            // Network errors or user aborts
            'Failed to fetch',
            'NetworkError when attempting to fetch resource.',
            'The network connection was lost.',
            'The request timed out.',
            'Load failed',
            'Fetch is aborted',
            'AbortError: AbortError',
            'AbortError: Fetch is aborted',
            'AbortError: The user aborted a request.',
            // HLS errors
            "Cannot read properties of null (reading 'currentTime')",
            "Cannot read properties of null (reading 'byteLength')",
            /(.+)?Event \`CustomEvent\` \(type=(.+)?/i,
            // https://github.com/WICG/resize-observer/issues/38
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            // iOS WebView error
            'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again.',
            'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
            // Extenstions
            /(.+)?ysmm(.+)?/i,
            "Identifier 'hideMyLocation' has already been declared",
            /(.+)?ibFindAllVideos(.+)?/i,
            /(.+)?MtPopUpList(.+)?/i,
            // Expected Firebase Messaging errors
            /(.+)?messaging\/permission\-blocked(.+)?/i,
            /(.+)?messaging\/unsupported\-browser(.+)?/i,
            // Expected Service Worker errors
            /(.+)?The user denied permission to use Service Worker(.+)?/i,
            // Youtube embed player errors
            /(.+)?getCurrentTime is not a function(.+)?/i,
            /(.+)?getDuration is not a function(.+)?/i,
            /(.+)?getRestrictions is not a function(.+)?/i,
            // Stripe error
            /(.+)?frame window is not ready(.+)?/i,
        ],
        denyUrls: [
            /^(safari|safari-web|chrome)-extension:\/\//i,
            // https://bugs.webkit.org/show_bug.cgi?id=246010
            /(.+)?webkit-masked-url(.+)?/i,
        ],
        beforeSend: (event, hint) => {
            const ignoredErrors = [
                ['CookieDeprecationLabel', 'gtm'],
                ['The operation is not supported', 'media-time-range'],
                ['null', 'clarity'],
                ['ReferenceError', 'find variable: gmo'],
                ['Firebase', 'auth/network-request-failed'],
            ];
            const isErrorIgnored = ignoredErrors.some(([error, source]) => 
            // @ts-ignore
            hint.originalException?.stack?.includes(error) &&
                // @ts-ignore
                hint.originalException?.stack?.includes(source));
            if (isErrorIgnored)
                return null;
            return event;
        },
        environment: window.__fw_app__.environment,
    });
    Sentry.setTag('userId', window.__fw_app__.userId);
}
