export const disableFormElements = (form) => Array.from(form.elements).forEach((element) => {
    if (!(element instanceof HTMLElement))
        return;
    if (element instanceof HTMLButtonElement) {
        element.dataset.loading = 'true';
    }
    else if ('disabled' in element) {
        element.disabled = true;
    }
});
export const enableFormElements = (form) => Array.from(form.elements).forEach((element) => {
    if (!(element instanceof HTMLElement))
        return;
    if (element instanceof HTMLButtonElement) {
        delete element.dataset.loading;
    }
    else if ('disabled' in element) {
        element.disabled = false;
    }
});
