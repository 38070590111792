import Cookies from 'js-cookie';
import jstz from 'jstz';
const findTimeZone = () => {
    const intlCopy = window.Intl;
    try {
        // @ts-ignore
        window.Intl = undefined;
        const timezone = jstz.determine().name();
        window.Intl = intlCopy;
        return timezone;
    }
    catch (e) {
        return jstz.determine().name();
    }
};
document.addEventListener('turbo:load', () => Cookies.set('browser_time_zone', findTimeZone(), { expires: 365, path: '/' }));
