import { Turbo } from '@hotwired/turbo-rails';
Turbo.StreamActions.hide = function () {
    document.querySelector(`[id=${this.target}]`)?.classList.add('hidden');
};
Turbo.StreamActions.show = function () {
    document.querySelector(`[id=${this.target}]`)?.classList.remove('hidden');
};
Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target);
};
