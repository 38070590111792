__webpack_public_path__ = `${window.__fw_app__.assetHost || ''}${__webpack_public_path__}`;
import './controllers';
import '../shared/local-time';
import '../shared/rails-ujs';
import '../shared/timezone';
import './application-cookie';
import './confirm';
import './custom-stream-actions';
import './elastic-apm';
import './firebase';
import './mobile-device';
import './pulljs';
import './sentry';
import './service-worker-communication';
import './turbo-events-listeners';
