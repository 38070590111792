import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Replaces DOM element with a placeholder when it's not visible.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <div data-controller="dom-garbage-collector">
 *   <div />
 * </div>
 */

export default class extends Controller {
  connect() {
    this.HTML = this.element.innerHTML
    this.height = this.element.offsetHeight

    setTimeout(() => {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // Played mux-players
            if (
              entry.target.firstElementChild &&
              entry.target.firstElementChild.querySelector('mux-player') &&
              entry.target.firstElementChild.querySelector('mux-player').readyState > 0
            ) {
              return
            }

            // Played embed videos
            if (
              entry.target.firstElementChild &&
              entry.target.firstElementChild.querySelector('[data-played]')
            ) {
              return
            }

            if (entry.isIntersecting) {
              // Render original content only if it's not already rendered
              if (entry.target.innerHTML.startsWith('<div style="height:')) {
                entry.target.innerHTML = this.HTML
              }
            } else {
              // Save content height and inner HTML only if original content rendered
              if (!entry.target.innerHTML.startsWith('<div style="height:')) {
                this.HTML = this.element.innerHTML
                this.height = this.element.offsetHeight
              }

              entry.target.innerHTML = `<div style="height: ${this.height}px"></div>`
            }
          })
        },
        {
          rootMargin: '2500px 0px 2500px 0px',
        },
      )

      this.observer.observe(this.element)
    }, 1000)
  }
}
