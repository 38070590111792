import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import { isPWA } from '../utils/dev'
import { scrollLock } from '../utils/scrollLock'

/*
 * Description
 * -------
 *
 * Handles custom PWA install banner.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Targets
 * -------
 *
 * prompt - (required) - prompt element
 * modal  - (required) - modal element opened on prompt click
 *
 * Values
 * -------
 *
 * cookie - (required) - cookie name to store user choice
 *
 * Usage
 * -------
 *
 * <div data-controller="install-prompt-custom" data-install-prompt-custom-cookie-value="hide_install_prompt_ios">
 *   <div class="hidden" data-install-prompt-custom-target="prompt">
 *     <button data-action="click->install-prompt-custom#closePrompt">Close</button>
 *     <button data-action="click->install-prompt-custom#openModal">Install</button>
 *   </div>
 *   <div class="hidden" data-install-prompt-custom-target="modal">
 *     <button data-action="click->install-prompt-custom#closeModal">Close</button>
 *   </div>
 * </div>
 */

export default class extends Controller {
  static targets = ['prompt', 'modal']

  static values = { cookie: String }

  connect() {
    if (!Cookies.get(this.cookieValue) && !isPWA()) {
      setTimeout(() => {
        this.promptTarget.classList.remove('hidden')
      }, 1000)
    }
  }

  openModal() {
    this.lastScrollTop = window.scrollY
    window.scrollTo(0, 0)

    this.promptTarget.classList.add('hidden')
    this.modalTarget.classList.remove('hidden')
    scrollLock('add')
  }

  closeModal() {
    window.scrollTo(0, this.lastScrollTop)
    this.lastScrollTop = null

    this.promptTarget.classList.remove('hidden')
    this.modalTarget.classList.add('hidden')
    scrollLock('remove')
  }

  closePrompt() {
    this.promptTarget.classList.add('hidden')
    Cookies.set(this.cookieValue, true, { expires: 7 })
  }
}
