import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { useDebounce } from 'stimulus-use'
import { isDesktop } from '../utils/dev'

/*
 * Description
 * -------
 *
 * Handles input.
 * Must be used on form element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * field - (required) - input field
 * clear - (optional) - clear button
 *
 * Values
 * -------
 *
 * type - (required) - type of input (search, date)
 *
 * Optional methods
 * -------
 *
 * submit() - submits input form
 * clear()  - clears input value
 *
 * Usage
 * -------
 *
 * // Search input
 * <div
 *   data-controller="input"
 *   data-input-type-value="search"
 * >
 *   <input data-input-target="field" data-action="input->input#handleInput" />
 *   <button data-input-target="clear" data-action="click->input#clear">Clear</button>
 * </div>
 *
 * // Date input
 * <div
 *   data-controller="input"
 *   data-input-type-value="date"
 * >
 *   <input data-input-target="field" />
 * </div>
 */

export default class extends Controller {
  static targets = ['field', 'clear']

  static values = {
    type: String,
  }

  static debounces = ['search']

  async connect() {
    if (this.typeValue === 'search') {
      this.lastSearchValue = this.fieldTarget.value

      useDebounce(this, {
        wait: 1000,
      })
    }

    if (this.typeValue === 'date') {
      const { default: flatpickr } = await import('flatpickr')

      this.flatpickr = flatpickr(this.fieldTarget, {
        disableMobile: true,
        position: 'above center',
        monthSelectorType: 'static',
        maxDate: new Date(),
        onOpen: () => this.element.classList.add('focus'),
        onClose: () => {
          this.element.classList.remove('focus')
          this.element.blur()
        },
      })
    }
  }

  handleInput(e) {
    if (this.typeValue === 'search') {
      this.search()

      e.target.value
        ? this.clearTarget.classList.remove('hidden')
        : this.clearTarget.classList.add('hidden')
    }
  }

  clear() {
    this.fieldTarget.value = ''
    Rails.fire(this.fieldTarget, 'input')
  }

  search() {
    if (this.lastSearchValue !== this.fieldTarget.value && isDesktop()) {
      this.lastSearchValue = this.fieldTarget.value
      this.submit()
    }
  }

  submit() {
    // Do not submit form if it is already submitted
    if (this.fieldTarget.form.getAttribute('aria-busy')) return

    Rails.fire(this.fieldTarget.form, 'submit')
  }

  disconnect() {
    this.flatpickr?.destroy()
  }
}
