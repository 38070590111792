import Cookies from 'js-cookie';
import { isPWA, isWLA } from './utils/dev';
const COOKIE_NAME = 'application';
if (isPWA()) {
    Cookies.set(COOKIE_NAME, 'PWA');
}
else if (isWLA()) {
    Cookies.set(COOKIE_NAME, 'WLA');
}
else {
    Cookies.remove(COOKIE_NAME);
}
