"use strict";
window.MobileDevice = {
    register: (name, model, system, firebaseToken) => {
        fetch('/supporters/notifications/mobiles/devices', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                model: model,
                system: system,
                firebaseToken: firebaseToken,
            }),
        })
            .then((response) => handleSuccess(response))
            .catch((error) => console.error('An unexpected error has occurred during the device registration:', error));
        const handleSuccess = (response) => {
            // Conflict is an expected result because this method will be used many times, even for the same Firebase token
            if (!(response.ok || response.status === 409)) {
                console.error('Could not register this device:', response);
            }
        };
    },
};
